import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ImageSlider from "./elements/ImageSlider"

const GiftVouchers = props => {
  const { vouchers } = useStaticQuery(graphql`
    query {
      vouchers: contentfulImageSlider(
        contentful_id: { eq: "4UHpnQgtj56wv7p46dUdUX" }
      ) {
        id
        images {
          ... on ContentfulAsset {
            id
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  return (
    <div className={props.className}>
      <h3>Geschenkgutscheine - mal anders!</h3>
      <p className="mb-4">
        Raus aus dem Alltag - rein ins Atelier und selber gestalten! Mit meinen
        Geschenkgutscheinen verschenkst du kreatives Arbeiten im Atelier.
        Alternativ zu einem Workshop, können Gutscheine auch für den Kauf einer
        Arbeiten eingelöst werden. Klingt super? Schreib mir eine Nachricht,
        gerne verschicke ich Gutschein per Post (1,50€ Versandkosten) oder du
        holst ihn im Galerie-Hotel ab.
      </p>
      <ImageSlider images={vouchers.images} height={130} />
    </div>
  )
}

export default GiftVouchers
