import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import ImageSlider from "./elements/ImageSlider"

const News = props => {
  const { news } = useStaticQuery(graphql`
    query {
      news: allContentfulNews {
        nodes {
          title
          updatedAt
          date
          text {
            text
          }
          images {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  return (
    <>
      <div className={props.className}>
        <h3>Aktuelles</h3>
        {news.nodes.map((news, i) => (
          <div key={i} className="mt-3">
            <small>{news.date}</small>
            <h4 className="text-primary">{news.title}</h4>
            <p style={{ whiteSpace: "pre-line" }}>{news.text.text}</p>
            {news.images && <ImageSlider images={news.images} height={150} />}
          </div>
        ))}
      </div>
      <hr style={{ margin: "5rem 0" }} />
    </>
  )
}
export default News
