import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const ProfileTeaser = props => {
  const { profile } = useStaticQuery(graphql`
    query {
      profile: contentfulArtist(
        contentful_id: { eq: "7wpPQwHouWUbwVVrS8nfmW" }
      ) {
        fistName
        lastName
        text {
          text
        }
        image {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <>
      <Row className={props.className}>
        <Col xs={12} md={6} className="mb-5 mb-md-0">
          <GatsbyImage
            style={{ maxWidth: "500px" }}
            loading="auto"
            image={getImage(profile.image)}
            alt="Anne Ewers"
          />
        </Col>
        <Col className="d-flex align-items-center">
          <div className="text-center text-md-left ">
            <p
              className="mb-4 text-primary display-4 text-center text-md-left font-italic"
              style={{ fontSize: "2.8rem" }}
            >
              „Ein Raum ohne Kunst ist wie ein Körper ohne Seele... "
            </p>
            <Link to="/anne-ewers" className="nav-link p-0">
              Künstlerin Anne Ewers
            </Link>
          </div>
        </Col>
      </Row>
      <hr style={{ margin: "3rem 0" }} />
    </>
  )
}

export default ProfileTeaser
