import React, { Component } from "react"
import { Link } from "gatsby"
import Carousel, { Modal, ModalGateway } from "react-images"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import SimpleReactLightbox from "simple-react-lightbox"
import { SRLWrapper } from "simple-react-lightbox"

import Button from "react-bootstrap/Button"

export default class GallerySlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      screenOrientation: "landscape",
      // screenOrientation: window.matchMedia("(orientation: portrait)").matches
      //   ? "portrait"
      //   : "landscape",
      modalOpen: false,
      selectedIndex: 0,
    }
    this.sliderRef = React.createRef()
  }
  toggle = i => {
    this.setState({ modalOpen: !this.state.modalOpen, selectedIndex: i })
  }
  isPortraitMode = () => {
    console.log(this.state)
    const { screenOrientation } = this.state
    return screenOrientation === "portrait"
  }

  setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      console.log("orientation: portrait")
      this.setState({
        screenOrientation: "landscape",
      })
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      console.log("orientation: landscape")
      this.setState({
        screenOrientation: "portrait",
      })
    }
  }

  componentDidMount() {
    window.addEventListener("orientationchange", this.setScreenOrientation)
  }

  render() {
    const scrollLeft = (element, change, duration) => {
      var start = element.scrollLeft,
        currentTime = 0,
        increment = 20

      var animateScroll = function() {
        currentTime += increment
        var val = Math.easeInOutQuad(currentTime, start, change, duration)
        element.scrollLeft = val
        if (currentTime < duration) {
          setTimeout(animateScroll, increment)
        }
      }
      animateScroll()
    }

    //t = current time
    //b = start value
    //c = change in value
    //d = duration
    Math.easeInOutQuad = function(t, b, c, d) {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t + b
      t--
      return (-c / 2) * (t * (t - 2) - 1) + b
    }

    const moveRight = () => {
      // const e = sliderRef.current
      // let i = 0

      // while (i < 3 && e.children[i].offsetLeft <= e.scrollLeft) {
      //   i++
      // }
      scrollLeft(
        this.sliderRef.current,
        this.sliderRef.current.clientWidth,
        1000
      )
    }
    const moveLeft = () => {
      scrollLeft(
        this.sliderRef.current,
        -this.sliderRef.current.clientWidth,
        1000
      )
    }

    const { images } = this.props
    const imageHeight = this.state.screenOrientation == "landscape" ? 60 : 40

    const options = {
      settings: {
        autoplaySpeed: 1500,
        transitionSpeed: 900,
      },
      buttons: {
        iconColor: "#4d7c8a",
        showAutoplayButton: false,
        showDownloadButton: false,
      },
      thumbnails: {
        showThumbnails: false,
      },
      caption: {
        showCaption: false,
      },
    }
    return (
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
          <div
            ref={this.sliderRef}
            className="scrolling-wrapper-flexbox"
            style={{ marginLeft: "-15px", marginRight: "-15px" }}
          >
            {images.map(image => {
              const { id } = image
              const { width, height } = getImage(image)

              return (
                <div key={id} className="ml-3" style={{ cursor: "pointer" }}>
                  <GatsbyImage
                    style={{
                      height: imageHeight + "vh",
                      width: (width / height) * imageHeight + "vh",
                    }}
                    loading="auto"
                    image={getImage(image)}
                    alt="Anne Ewers"
                  />
                </div>
              )
            })}

            <div className="d-flex align-items-center px-3">
              <Link to="/werke">mehr entdecken...</Link>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              variant="link"
              size="lg"
              className="pl-0 pr-2"
              style={{ textDecoration: "none" }}
              onClick={moveLeft}
            >
              &larr;
            </Button>
            <Button
              variant="link"
              size="lg"
              className="pr-0 pl-2"
              style={{ textDecoration: "none" }}
              onClick={moveRight}
            >
              &rarr;
            </Button>
          </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    )
  }
}
