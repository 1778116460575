import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import moment from "moment"
import Badge from "react-bootstrap/Badge"

const NextWorkshop = props => {
  const { workshops } = useStaticQuery(graphql`
    query {
      workshops: allContentfulWorkshop(
        sort: { fields: startDate, order: ASC }
      ) {
        nodes {
          startDate
          type
          state
        }
      }
    }
  `)

  const futureWorkShops = workshops.nodes.filter(node =>
    moment(node.startDate).isAfter(moment())
  )

  if (futureWorkShops.length == 0) return null

  return (
    <>
      <div className={props.className}>
        <h3>Meine nächsten Kurstermine</h3>
        <div className="d-flex mt-3 scroll-vertically">
          {futureWorkShops.map(node => (
            <div className="workshop">
              <span className="text-primary display-1">
                {moment(node.startDate).format("DD")}
              </span>
              <span className="text-uppercase">
                {moment(node.startDate).format("MMM")}
              </span>
              <hr />
              <div>{node.type}</div>
              <Badge variant="secondary">{node.state}</Badge>
            </div>
          ))}
          {/* <div className="workshop">
            <span className="text-primary display-1">8</span>
            <span className="text-uppercase">Jan</span>
            <hr />
            <div>Wochenendsemiar</div>
            <div>Schnupperkurs</div>
          </div>
          <div className="workshop">
            <span className="text-primary display-1">12</span>
            <span className="text-uppercase">Mär</span>
            <hr />
            <div>Halbtageskurs</div>
          </div>
          <div className="workshop">
            <span className="text-primary display-1">28</span>
            <span className="text-uppercase">mai</span>
            <hr />
            <div>Wochenendsemiar</div>
            <div>Schnupperkurs</div>
          </div> */}
        </div>
      </div>
      <hr style={{ margin: "3rem 0" }} />
    </>
  )
}

export default NextWorkshop
