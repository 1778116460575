import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Page from "../templates"
import SEO from "../templates/seo"

import NextWorkshops from "../components/NextWorkshops"
import GiftVouchers from "../components/GiftVouchers"
import GallerySlider from "../components/GallerySlider"
import News from "../components/News"
import ProfileTeaser from "../components/ProfileTeaser"
import StudioSliderStart from "../components/StudioSliderStart"

const IndexPage = () => {
  const { images } = useStaticQuery(graphql`
    query {
      images: contentfulImageSlider(
        contentful_id: { eq: "2WT6kYApmx6VAN9f6wpXuq" }
      ) {
        id
        images {
          ... on ContentfulAsset {
            id
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  return (
    <Page>
      <SEO title="Home" />
      <GallerySlider images={images.images} />
      <div className="mt-4 mt-lg-3">
        <h2 className="text-primary">Ausstellung & Verkauf</h2>
        <p>
          Eine ständige Ausstellung meiner Werke findet du im{" "}
          <u>
            <a href="https://www.galerie-hotel.de" target="_blank">
              La Petite Galerie & Galerie-Hotel
            </a>
          </u>{" "}
          in Paderborn, täglich von 9:00 bis 23:00 Uhr geöffnet. Nach vorheriger
          Vereinbarung öffne ich gerne mein Atelier für dich und gebe dir eine
          persönliche Führung!
        </p>
      </div>

      <hr style={{ margin: "5rem 0" }} />
      <News className="my-5" />
      <ProfileTeaser className="my-5" />
      <StudioSliderStart className="my-5" />
      <NextWorkshops className="my-5" />
      <GiftVouchers className="my-3" />
    </Page>
  )
}

export default IndexPage
